import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SELLERS } from '../../../../graphql/seller.queries';
import { Tooltip } from '@ifood/pomodoro-components';
import { Button, OutlinedInput, Link, Modal, Typography, Grid } from 'ifoodshop-react-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import Toggle from 'react-toggle';
import moment from 'moment';
import ptbr from 'date-fns/locale/pt-BR';

import Roles from '@helpers/Roles';
import { getRoles, getSellerId } from '@helpers/rolesUtils';
import AuthorizationHeader from '@helpers/AuthorizationHeader';

import TooltipContent from '@components/TooltipContent';

import './filterOrderSearch.scss';
import { api } from '@services/api';

let timeoutVisible;

const FiltersOrderSearch = ({ variables, onSubmitFilters, isSeller, idSeller, sessionOrders }) => {
  const rangeOfDays = 90;
  const miliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;
  const [filters, setFilters] = useState(variables);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stateLinkHidden, setStateLinkHidden] = useState(false);

  const startDatePickerRange = new Date('01/01/2017');

  const optionsStatus = [
    { value: null, label: 'Todos' },
    { value: 'WAITING_PAYMENT', label: 'Aguardando pagamento' },
    { value: 'PAYMENT_ACCEPTED', label: 'Pagamento confirmado' },
    { value: 'IN_STOCK_SEPARATION', label: 'Em separação' },
    { value: 'BILLED', label: 'Faturado' },
    { value: 'IN_TRANSPORTATION', label: 'Em transporte' },
    { value: 'WAITING_PICKUP', label: 'Aguardando coleta' },
    { value: 'FINISHED', label: 'Finalizado' },
    { value: 'CANCELLED', label: 'Cancelado' },
  ];
  const optionsPayment = [
    { value: null, label: 'Todos' },
    { value: 'PROCESSING', label: 'Processando' },
    { value: 'APPROVED', label: 'Aprovado' },
    { value: 'DECLINED', label: 'Negado' },
    { value: 'CANCELLED', label: 'Cancelado' },
  ];

  const [getSellers, { data: dataSeller }] = useLazyQuery(GET_SELLERS);
  const roles = getRoles();
  const roleSeller = roles.includes(Roles.SELLER_WR);
  const roleSellerReadOnly = roles.includes(Roles.SELLER_RO);
  const roleRupture = roles.includes(Roles.RUPTURA_LOTE_WR);
  const [isVisible, setIsVisible] = useState(true);
  const [stateFocusProduct, setStateFocusProduct] = useState(false);

  const handleSearchSeller = name => {
    getSellers({ variables: { name } });
  };

  const handleSelectSeller = sellerName => {
    const seller = dataSeller?.getSellerByName?.find(item => item.name === sellerName) || null;
    setFilters({
      ...filters,
      sellerId: seller?.id || null,
    });
  };

  const handleFilter = (name, value) => {
    if (name === 'orderNumber') {
      if (value === '')
        return setFilters({
          ...filters,
          orderNumber: null,
          orderSellerUuid: null,
        });
      else if (isNaN(value) && value !== '') name = 'orderSellerUuid';
      else value = parseInt(value);
    }

    if (value === '') {
      value = null;
    }

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleOrderDelayed = isLater => {
    let inputChecked = document.getElementById('orderDelayedToggle');

    if (inputChecked.checked) {
      setFilters({
        ...filters,
        isLater: isLater ? true : true,
      });

      sessionOrders.events.orders.clickToggleLateOrders();
    } else {
      setFilters({
        ...filters,
        isLater: isLater ? false : false,
      });
    }
  };

  const exportOrdersByFilter = sellerId => {
    let date = new Date();
    setLoading(true);

    const {
      startDate,
      endDate,
      orderNumber,
      orderClient,
      orderStatus,
      paymentStatus,
      orderDeliveryCode,
      isLater,
      invoiceNumber,
      shortCode,
    } = filters;

    api
      .post(
        `order/export/by/filter/${sellerId}`,
        JSON.stringify({
          startDate,
          endDate,
          orderNumber,
          orderClient,
          orderStatus,
          paymentStatus,
          orderDeliveryCode,
          isLater,
          invoiceNumber,
          shortCode,
        }),
        { headers: { 'content-type': 'application/json' } }
      )
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Status pedidos - Exportação ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        changeModal('Atenção', 'Erro  ao exportar arquivo');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rulerSeller = roles.includes('SELLER-WR') && roles.includes('SELLER-RO');

  const showModalExport = () => {
    const sellerId = getSellerId();
    const sellerIdLojista = filters.sellerId;

    const inicio = moment(filters.startDate, 'DD/MM/YYYY');
    const fim = moment(filters.endDate, 'DD/MM/YYYY');
    const diffTime = Math.abs(inicio - fim);
    const dias = Math.ceil(diffTime / (miliseconds * seconds * minutes * hours));

    if (rulerSeller == false && dias <= rangeOfDays && filters.sellerId) {
      exportOrdersByFilter(sellerIdLojista);
    } else if (rulerSeller && dias <= rangeOfDays && sellerId) {
      exportOrdersByFilter(sellerId);
    } else if (sellerId) {
      modalSeller();
    } else {
      modalSellerLojista();
    }

    function modalSellerLojista() {
      return setModal(
        <Modal onCloseAction={() => setModal(null)} title="Exportar pedidos">
          <div className="modal-update-import-notice">
            <Grid wrap>
              <Grid.Item xs={12}>
                <div className="modal-update-import-notice__subtitle">
                  <Typography size="16px" weight="regular" line="16px" color="heavygrey">
                    É necessário definir um período <strong>de até 3 meses</strong> entre a data inicial e final de
                    pedidos.
                  </Typography>
                </div>
              </Grid.Item>
              <Grid.Item xs={12}>
                <div className="modal-update-import-notice__subtitle">
                  <Typography size="16px" weight="regular" line="16px" color="heavygrey">
                    É necessário preencher o campo <strong>Lojista</strong>
                  </Typography>
                </div>
              </Grid.Item>
            </Grid>
            <Grid wrap>
              <Grid.Item xs={12}>
                <div
                  className="modal-update-import-notice__button-ifilters.orderClientmport"
                  onClick={() => setModal(null)}
                >
                  <label className="button" for="importUpdateOffers">
                    Filtrar período
                  </label>
                </div>
              </Grid.Item>
            </Grid>
          </div>
        </Modal>
      );
    }

    function modalSeller() {
      return setModal(
        <Modal onCloseAction={() => setModal(null)} title="Exportar pedidos">
          <div className="modal-update-import-notice">
            <Grid wrap>
              <Grid.Item xs={12}>
                <div className="modal-update-import-notice__subtitle">
                  <Typography size="16px" weight="regular" line="16px" color="heavygrey">
                    É necessário definir um período <strong>de até 3 meses</strong> entre a data inicial e final de
                    pedidos.
                  </Typography>
                </div>
              </Grid.Item>
            </Grid>
            <Grid wrap>
              <Grid.Item xs={12}>
                <div
                  className="modal-update-import-notice__button-ifilters.orderClientmport"
                  onClick={() => setModal(null)}
                >
                  <label className="button" for="importUpdateOffers">
                    Filtrar período
                  </label>
                </div>
              </Grid.Item>
            </Grid>
          </div>
        </Modal>
      );
    }
  };

  const changeModal = (title, message) => {
    setModal(
      <Modal onCloseAction={() => setModal(null)} title={title}>
        <p>{message}</p>
      </Modal>
    );
  };

  useEffect(() => {
    if (stateFocusProduct) {
      clearTimeout(timeoutVisible);
    } else {
      timeoutVisible = setTimeout(() => {
        setIsVisible(false);
      }, 8000);
    }
  }, [stateFocusProduct]);

  return (
    <div className="header-customer-group header-customer-group--src header-order form-order">
      <form
        className="header-customer-group__form"
        onSubmit={e => {
          e.preventDefault();
          onSubmitFilters(filters);
        }}
      >
        <div className="header-customer-group__form-input">
          <OutlinedInput
            name="orderNumber"
            placeholder="Número ID ou UUID"
            border
            value={filters.orderNumber}
            onChange={e => handleFilter('orderNumber', e.target.value)}
          />

          <OutlinedInput
            name="shortCode"
            placeholder="Short ID"
            type="number"
            border
            value={filters.shortCode}
            onChange={e => handleFilter('shortCode', e.target.value)}
          />    

          <OutlinedInput
            name="invoiceNumber"
            placeholder="Nota Fiscal"
            type="number"
            border
            value={filters.invoiceNumber}
            onChange={e => handleFilter('invoiceNumber', e.target.value)}
          />

          <Tooltip content="CNPJ, Nome ou Email" placement="bottom" trigger="hover">
            <OutlinedInput
              name="orderClient"
              placeholder="Cliente"
              border
              value={filters.orderClient}
              onChange={e => handleFilter('orderClient', e.target.value)}
            />
          </Tooltip>

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsStatus}
              value={
                filters.product ? null : optionsStatus.find(o => o.value === filters.orderStatus) || optionsStatus[0]
              }
              disabled={filters.product}
              onChange={e => handleFilter('orderStatus', e.value)}
              placeholder="Status"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Status</label>
          </div>

          <div className="dropdown-label-wrap">
            <Dropdown
              options={optionsPayment}
              value={optionsPayment.find(o => o.value === filters.paymentStatus) || optionsPayment[0]}
              name="paymentStatus"
              onChange={e => handleFilter('paymentStatus', e.value)}
              placeholder="Pagamento"
              className="dropdown-ifood"
              controlClassName="dropdown-ifood__control"
              placeholderClassName="dropdown-ifood__placeholder"
              menuClassName="dropdown-ifood__menu"
              arrowClassName="dropdown-ifood__arrow"
            />
            <label className="dropdown-label">Pagamento</label>
          </div>
        </div>

        <div className="header-customer-group__form-input">
          {!roleSeller && !roleSellerReadOnly && (
            <div className="dropdown-label-wrap">
              <Autocomplete
                id="sellerSearch"
                freeSolo
                onSelect={e => handleSelectSeller(e.target.value)}
                className="autocomplete-ifood"
                noOptionsText="Digite para procurar"
                options={dataSeller?.getSellerByName ? dataSeller.getSellerByName.map(option => option.name) : []}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => handleSearchSeller(e.target.value)}
                    label="Lojista"
                    variant="outlined"
                  />
                )}
              />
            </div>
          )}

          <div
            id="startDatePickerOrders"
            className={`outlined-label-wrap outlined-date ${filters.startDate ? 'active' : ''}`}
          >
            <DatePicker
              className="form-brand__input"
              onChange={e => handleFilter('startDate', e)}
              locale={ptbr}
              selected={filters.startDate}
              dateFormat="dd/MM/yyyy"
              minDate={startDatePickerRange}
              maxDate={filters.endDate || new Date()}
            />
            <label className="outlined-label">Data Inicial da Pesquisa</label>
          </div>

          <div
            id="endDatePickerOrders"
            className={`outlined-label-wrap outlined-date ${filters.endDate ? 'active' : ''}`}
          >
            <DatePicker
              className="form-brand__input"
              onChange={e =>
                setFilters({
                  ...filters,
                  endDate: e || null,
                })
              }
              locale={ptbr}
              selected={filters.endDate}
              dateFormat="dd/MM/yyyy"
              minDate={startDatePickerRange}
              maxDate={new Date()}
            />
            <label className="outlined-label">Data Final da Pesquisa</label>
          </div>

          <OutlinedInput
            name="deliveryCode"
            placeholder="Cód. Entrega"
            border
            onChange={e => handleFilter('orderDeliveryCode', e.target.value)}
          />
          {roleRupture && (
            <Tooltip
              content={
                <TooltipContent
                  iconClose
                  setIsVisible={setIsVisible}
                  title="Filtre por produto"
                  content="E visualize os pedidos em andamento que possuem esse item"
                />
              }
              placement="bottom"
              trigger="none"
              visible={isVisible}
            >
              <OutlinedInput
                name="product"
                placeholder="Produto"
                border
                autocomplete="off"
                onFocus={() => {
                  setIsVisible(true);
                  setStateFocusProduct(true);
                }}
                onBlur={() => setIsVisible(false)}
                onChange={e => handleFilter('product', e.target.value)}
              />
            </Tooltip>
          )}
          <div className="toggle-wrap">
            <Toggle name="orderDelayed" id="orderDelayedToggle" onChange={e => handleOrderDelayed(e)} />
            <span className="toggle-wrap__label">Pedidos atrasados</span>
          </div>
        </div>

        <div className="export-order-admin-grid">
          <Button type="submit">Filtrar Pedido</Button>
          <div className="contentLinkExport" hidden={stateLinkHidden}>
            <span className="export-order-admin-icon"></span>
            <Link className="header-search-order__action linkExport" onClick={showModalExport}>
              Exportar pedidos
            </Link>
          </div>
        </div>
      </form>
      {modal}
      {loading}
    </div>
  );
};

export default FiltersOrderSearch;
