import { Flex, Heading1 } from '@ifood/pomodoro-components';

import BulkImport, { EnumHeaderOptionsType, HeaderOptionsType } from '@components/BulkImport';
import { CustomerListHeaderType } from './types';
import * as S from './CustomerListHeader.styles';
import Roles from '@helpers/Roles';
import { getRoles } from '@helpers/rolesUtils';
import { useMemo } from 'react';

import TemplateOwnerCustomer from '@files/templateOwnerCustomer.csv';
import bonusShopImportOptions from './BonusShopOptions/BonusShopOptions';
import postPaidBankSlipOptions from './PostPaidBankSlipOptions/PostPaidBankSlipOptions';

const options: HeaderOptionsType[] = [
  {
    name: 'Modelo importação de owner',
    value: 'owner-download',
    template: TemplateOwnerCustomer,
    roles: [Roles.CUSTOMER_OWNER_WR],
    type: EnumHeaderOptionsType.DOWNLOAD,
  },
  {
    name: 'Importação de owner',
    value: 'owner-import',
    roles: [Roles.CUSTOMER_OWNER_WR],
    type: EnumHeaderOptionsType.UPLOAD,
  },
];

const CustomerListHeader = ({ titleHeader }: CustomerListHeaderType): JSX.Element => {
  const roles = getRoles();

  const requiredRoles = useMemo(
    () => [Roles.CUSTOMER_OWNER_WR, Roles.BONUS_SHOP_IMPORT, Roles.POST_PAID_BANK_SLIP_IMPORT],
    []
  );

  const hasSomeImportRoles = useMemo(
    () => roles.some((role: string) => requiredRoles.includes(role)),
    [requiredRoles, roles]
  );

  return (
    <S.ContentHeader>
      <Flex justifyContent="space-between" style={{ width: '100%' }}>
        <Heading1 fontWeight="medium" my={10} variant="regular">
          {titleHeader}
        </Heading1>
        {hasSomeImportRoles && (
          <BulkImport
            dialogTitle="Importar alteração de owner"
            label="Ver mais ações"
            options={[...options, ...bonusShopImportOptions, ...postPaidBankSlipOptions]}
            uploadUrl="/customer/upload-update-owner-customer"
          />
        )}
      </Flex>
    </S.ContentHeader>
  );
};

export default CustomerListHeader;
