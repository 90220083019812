/* istanbul ignore file */
import { HeaderOptionsType, EnumHeaderOptionsType } from '@components/BulkImport';
import ModalImportFile from '@components/ModalImportFile';
import Roles from '@helpers/Roles';
import TemplatePostPaidBankSlip from '@files/templateImportPostPaidBankSlip.csv';

const postPaidBankSlipOptions: HeaderOptionsType[] = [
  {
    name: 'Modelo importação Boleto à prazo',
    value: 'post-paid-bank-slip-download',
    template: TemplatePostPaidBankSlip,
    roles: [Roles.POST_PAID_BANK_SLIP_IMPORT],
    type: EnumHeaderOptionsType.DOWNLOAD,
  },
  {
    name: 'Importação de Boleto à prazo',
    type: EnumHeaderOptionsType.UPLOAD,
    value: 'post-paid-bank-slip-import',
    roles: [Roles.POST_PAID_BANK_SLIP_IMPORT],
    action: (callback?: { closeModal: (value: any) => void }) => {
      return (
        <ModalImportFile
          showConfirmationDialog
          description=""
          feedbackType="snackbar"
          fileType="csv"
          setModal={callback?.closeModal}
          showEmailInput={false}
          title="Importação de Boleto à prazo"
          urlImport="wallet/POST_PAID_BANK_SLIP/import"
        />
      );
    },
  },
];

export default postPaidBankSlipOptions;
